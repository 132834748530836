.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: rgba(31, 31, 31, 0.95);
    
}
.header .center {
    width: 870px;
    margin: 0 auto;
}
.header .time{
    margin: 5px 15px 5px 15px;
    height: 50px;
    color: #fff;
    font-size: 15px;
    float: right;
}
.header button {
    height: 50px;
    margin: 5px 0 5px 0;
    float:right;
    border: solid 1px #fff;
    border-radius: 10px;
    font-family: 'Courier New', Courier, monospace;
    color: rgb(23, 23, 23);
}
.header p{
    font-size: 23px;
    margin: 0;
    padding: 0;
}
@media screen and (max-width: 480px) {
    .header .center {
        width: 100%;
    }
    .header p{
        font-size: 12px;
    }
    .header button{
        margin: 10px 0 10px 0;
        height: 50px;
        width: 30%;
        min-width: 98px;
    }
    .header .time{
        display: none;
    }
}