.footer{
    padding:20px 0 5px 0;
    background:#1f1f1f;
    border-top:1px solid #555;
    color:#fff;
    font-size: 20px;
    margin: 0;
}
.width{
    margin: 0 auto;
}
.tel{width: auto;}
.tel strong{
    font-size:20px;
    font-weight:bold;
}

.footer a, .footer p{color:#fff;}
.footer a:hover{color:#ccc;}

.footer .logo{
    width: auto;
    font-size: 20px;
    float: left;
}
.footer .tenpo_detail{
    text-align: left;
    margin: 0 0 0 auto;
}
.footer .copyright{
    text-align: right;
}
.copyright p{	
    color: #aca9a994;
    margin: 5px 0 0 5px;
    font-size:11px;
}
@media screen and (min-width :481px){
    .width{
        width: 870px;
    }
    .tenpo_detail{
        width: 320px;
    }
    .footer .tenpo_detail p{
        font-size: 15px;
    }
}

@media screen and (max-width: 480px){
    .footer{
        padding:20px 10px 5px 10px;
    }
    .width{
        width: 100%;
        min-width: 285px;
    }
    .tel{
        width: 100%;
    }
    .tel strong{
        font-size: 15px;
    }
    .tenpo_detail{
        width: 60%;
    }
    .tenpo_detail p{
        font-size: 10px;
    }
    .logo{
        width: 100%;
        text-align: center;
    }
    .logo img{
        width: 100%;
    }
    .copyright p{
        font-size: 7px;
    }
    
}