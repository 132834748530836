.inner{
    padding:60px 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
	margin: 0 auto;
}
.inner h1{
	font-size:24px;
	font-weight:normal;
	margin-left: 5px;
}

.logo-title{
	margin-bottom: 2px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}
.logo-title p{
	margin-left:5px
}
.logo-main{
	font-size:40px;
	line-height:1;
}
.logo-sub{
	font-size: 20px;
    padding-top:15px
}
.inner img{
	height: 276px;
	border:1px solid #ccc;
}

.detail{
	height: 350px;
	margin-top:32px
}
.inner .attension{
	background-color: rgba(106, 170, 243, 0.877);
}
.inner .attension p{
	color: whitesmoke;
	font-size:20px;
	text-align: center;
    margin: 0px;
}
.explain{
	height: 300px;
	border-radius: 0px 0px 5px 5px;
	border: 1px solid #ccc;
	background-color: #f5f5f5;  
}
.explain p{
	margin: 10px 10px 0px 10px;
    font-size: 20px;
}

.tel a{
	font-size: 30px;
}

.time{
	font-size: 20px;
}

.parking{
	color:rgb(212, 23, 23);
	font-size: large;
}
@media  screen and (min-width: 481px){
	.inner{
		min-width: 870px;
		width: 870px;
	}
	.inner .detail{
		min-width: 540px;
	}
	
}
@media screen and (max-width: 480px){
	.inner{
		width: 90%;
		flex-direction: column;
		min-width: 285px;
	}
	.inner img{
		width: 100%;
		height: auto;
	}
	.detail{
		width: 100%;
	}
	.detail p{
		font-size: 15px;
	}

	.explain{
		width: 100%;
		height: auto;
		padding-bottom: 5px;
	}
	
}

