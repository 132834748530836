.menuWrapper{
	padding-bottom:20px;
	overflow: hidden;
    width: 870px;
    margin: 0 auto;
}

.menuWrapper .attention{
    font-size: 30px;
	text-align: center;
	color: rgba(213, 36, 36, 0.916);
}

.grid{
    width: 24.75%;
	float:left;
	border-radius: 5px;
	border: 1px solid #ccc;
	background: #f5f5f5;   
    margin-bottom: 2px;
}
.grid h2{
    margin: 0;
	font-size:15px;
	padding:5px;
	font-weight:bold;
	text-align:center;
}
.grid h3{
    margin: 0;
	text-align: center;
	padding:5px 5px;
    font-size: 12px;
}

.grid p{
	font-size: medium;
    padding: 0 10px 0 10px;
}

.grid p.img{
	text-align:center;
}

.grid img{
    text-align: center;
	max-width:100%;
	height:auto;
	margin-bottom:5px;
	border:1px solid #ccc;
    border-radius: 10px;
	background:#eee;
}


.attension h1{
	font-size: 30px;
	text-align: center;
	color: rgba(213, 36, 36, 0.916);
}

.menu{
    color:whitesmoke;
    float: left;
    height: auto;
	font-size: 20px;
	margin: 30px 0 5px;
    padding: 5px 0 5px 0;
	width: 100%;
	border:1px solid #ccc;
	background-color: rgb(106, 170, 243, 0.877);
    border-radius: 10px;
}

.menuname{
	background-color: rgba(180, 183, 188, 0.877);
	color: rgb(99, 92, 92);
}
.tanpin .grid{
    height: 390px;
}
.tanpin .quantityPrice{
	padding: 2px;
	height: auto;
}
.tanpin .quantityPrice h3{
	padding: 2px;
}
.sideMenu .grid{
    height: 430px;
}
.sideMenu .quantityPrice{
    display: flex;
    flex-direction: column;
    padding: 2px;
    height: 84px;
}
.bento .grid{
    height: 380px;
}
.otherMenu .grid{
    height: 380px;
}

@media screen and (max-width: 480px) {
    .menuWrapper{
        width: 90%;
        flex-direction: column;
        min-width: 300px;
    }
    .menuWrapper .attention{
        font-size: 20px;
    }
    .menu {
        font-size: 17px;
    }
    .grid{
        width: 49.3%;
    }
    .tanpin .grid{
        height: 400px;
    }
    .bento .grid{
        height: 400px;
    }
    .otherMenu .grid{
        height: 390px;
    }
}